<template>
  <div class="login-page">
    <div class="page-main">
      <a-spin :spinning="loadingForm">
        <a-form
          ref="formRef"
          :model="form"
          class="login-form box-shadow-1"
          @finish="handleSubmit"
        >
          <div class="logon-title flex">
            <img src="~@/assets/logo.png" alt="" />
            技领学习平台
          </div>
          <a-alert
            v-if="isLoginError"
            type="error"
            show-icon
            style="margin-bottom: 24px"
            :message="errorMessage"
          />
          <a-form-item
            name="user_phone"
            :rules="{ required: true, message: '请输入账号' }"
          >
            <a-input size="large" v-model:value="form.user_phone" allow-clear>
              <template #prefix>
                <user-outlined class="prefixIcon" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="user_password"
            :rules="{ required: true, message: '请输入密码' }"
          >
            <a-input-password
              size="large"
              v-model:value="form.user_password"
              allow-clear
            >
              <template #prefix> <lock-outlined class="prefixIcon" /> </template
            ></a-input-password>
          </a-form-item>
          <a-form-item class="login-button-box">
            <a-button
              block
              size="large"
              type="primary"
              html-type="submit"
              class="login-button button-color-blue"
              :loading="loadingBtn"
              @click="handleSubmit"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from "@vue/runtime-core";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { message, notification } from "ant-design-vue";
import url from "../utils/url";
// import ls from "@/utils/local-storage";
import { useStore } from "vuex";
// import { onLogin } from "@/api/main.js";

export default defineComponent({
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const store = useStore();
    const formRef = ref();
    const pageData = reactive({
      isLoginError: false,
      errorMessage: null,
      loginBtn: false,
      form: {
        user_phone: null,
        user_password: null,
      },
      loadingBtn: false,
      loadingForm: false,
    });
    const handleSubmit = (e) => {
      e.preventDefault();
      pageData.loadingBtn = true;
      pageData.loadingForm = true;
      formRef.value
        .validate()
        .then((res) => {
          console.log(res);
          store
            .dispatch("LOGIN", { ...res })
            .then(() => {
              notification.success({
                message: "登录成功",
                duration: 1.5,
                description: "正在跳转页面中，请稍后……",
                onClose: () => {
                  url.navigateTo("/");
                  // location.reload();
                  pageData.loadingForm = false;
                },
              });
            })
            .catch((res2) => {
              console.log("res2", res2);
              pageData.isLoginError = true;
              pageData.errorMessage = res2.msg;
              pageData.loadingForm = false;
            })
            .finally(() => {
              pageData.loadingBtn = false;
            });
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };

    return {
      ...toRefs(pageData),
      handleSubmit,
      formRef,
    };
  },
});
</script>

<style lang="less" scoped>
.login-page {
  // min-width: 1200px;
  height: 100%;
  .page-main {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .login-form {
    width: 450px;
    margin: 0 auto;
    background: #fff;
    padding: 30px 50px;
    border-radius: 20px;
    .logon-title {
      font-size: 32px;
      margin-bottom: 40px;
      justify-content: center;
      font-weight: 700;
      img {
        display: block;
        width: 44px;
        height: 44px;
        margin-right: 20px;
      }
    }
    .login-button-box {
      padding-top: 20px;
    }
    .prefixIcon {
      color: #1890ff;
    }
  }
}
</style>
